<template>
  <div class="grid-x grid-padding-x">
    <div class="cell small-12 medium-6 medium-offset-3 bordered-alt pa-5 mt-5">
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 text-center relative">
          <router-link class="close-thankyou" to="/">
            <img src="../assets/icons/red_close.svg" alt="" />
          </router-link>
          <h1 class="logo-text pt-5">
            Customised <span>Clothing</span>
          </h1>
        </div>
      </div>
      <div class="grid-x grid-padding-x pt-5">
        <div class="cell small-12 text-center">
          <h2 class="thank-you-text">Thank you for your<br />recent order!</h2>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12">
          <p class="text-blue text-heavy">
            Name: {{ order.customer.first_name }} {{ order.customer.last_name }}<br />
            Order no: {{ order.id }}<br />
            Email: {{ order.customer.email }}
          </p>
          <p class="text-blue">
            You will receive a visual proof for approval prior to production, once this is approved
            we will provide an estimated delivery date.
          </p>
        </div>
      </div>
      <div class="grid-x grid-padding-x">
        <div class="cell small-12 text-center">
          <router-link to="/">
            <button class="button ghost expanded mb-4">Shop</button>
          </router-link>
          <router-link :to="`/invoice/${orderId}`">
            <button class="button ghost expanded mb-4">View invoice</button>
          </router-link>
          <router-link to="/profile">
            <button class="button rounded expanded">Go to profile</button>
          </router-link>
        </div>
      </div>
      <div class="grid-x grid-padding-x pt-5">
        <div class="cell small-12 text-center">
          <img width="40px" src="../assets/icons/cc_logo_blue.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../axios';

export default {
  name: 'Thankyou',
  data() {
    return {
      order: {
        customer: {},
      },
    };
  },
  computed: {
    orderId() {
      return this.$route.params.id;
    },
  },
  methods: {
    getOrder() {
      axios.get(`/orders/getOrder/${this.orderId}.json`)
        .then((response) => {
          this.order = response.data.order;
        });
    },
  },
  mounted() {
    this.getOrder();
  },
};
</script>

<style lang="scss" scoped>
  .close-thankyou {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 20px;
  }
  .thank-you-text {
    font-family: 'rennersemi';
    font-size: 18px;
    font-weight: bold;
  }
</style>
